

























import { Vue, Component } from "vue-property-decorator";
import Subscribers, { Subscriber } from "@/store/subscribers";
import * as R from "ramda";

@Component
export default class extends Vue {
  model = {
    name: "",
    email: ""
  };
  originalModel: any = {};

  subscriptionTypes = [
    { text: "ANNUAL", value: "ANNUAL" },
    { text: "MONTHLY", value: "MONTHLY" }
  ];

  subscriptionStatuses = [
    { text: "Active", value: "ACTIVE" },
    { text: "Inactive", value: "INACTIVE" }
  ];

  error = "";
  isLoading = false;

  get id() {
    return this.$route.params.id;
  }

  async mounted() {
    this.isLoading = true;
    try {
      const response = await Subscribers.fetchOne({ id: this.id });
      const fetchedSubscriber = response.data as Subscriber;
      (this.model as any) = R.clone(fetchedSubscriber);
      this.originalModel = R.clone(fetchedSubscriber);
    } catch (err) {
      this.error = err.message;
    }
    this.isLoading = false;
  }

  async save() {
    this.isLoading = true;
    try {
      await this.saveEdited();
    } catch (err) {
      this.error = err.message;
    }
    this.isLoading = false;
  }

  get changes() {
    const retUndef = (arg: any) => (arg ? arg : undefined);
    const eq = (a1: any, a2: any) => (R.equals(a1, a2) ? false : a1);

    return R.mergeWith(R.compose(retUndef, eq), this.model, this.originalModel);
  }

  async saveEdited() {
    await Subscribers.update({ id: this.id, data: this.changes });

    this.$router.push("/subscribers");
  }
}
